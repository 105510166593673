button.hotel-btn {
  padding: 8px 16px;
  background-color: #07c;
  color: #fff;
  border: none;
  border-radius: 2px;

  span.MuiButton-label {
    font-weight: bold !important;
  }

  .MuiSvgIcon-root.h-icon {
    color: #fff !important;
    font-size: 1.2rem;
    margin-left: 8px;
  }
}
button.hotel-btn:hover {
  background-color: #005999;
}
.fg-blue {
  color: #0c80d1 !important;
}
.fg-green {
  color: #00a651 !important;
}
.fg-red {
  color: #ee3a52 !important;
}
.h-bold {
  font-weight: 700;
}
.s-bold {
  font-weight: bold;
}
.s-green {
  color: #008009;
}
.s-italic {
  font-style: italic;
}
.s-underline {
  text-decoration: underline;
}
.d-flex {
  display: flex;
}
.mobile-title {
  font-size: 18px !important;
}
.mobile-title.-u {
  text-transform: uppercase;
  font-weight: bold;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-start {
  justify-content: start;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-evenly {
  justify-content: space-evenly;
}
.justify-content-around {
  justify-content: space-around;
}
.justify-content-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
.align-items-stretch {
  align-items: stretch;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}
.flex-grow {
  flex-grow: 1;
}
.flex-col {
  flex-direction: column;
}
.MuiSvgIcon-root.h-icon.blue-icon {
  color: #0c80d1 !important;
}
.MuiSvgIcon-root.h-icon.white-icon {
  color: #fff !important;
}
.MuiSvgIcon-root.h-icon.yellow-icon {
  color: #eba101 !important;
}
.MuiSvgIcon-root.h-icon.grey-icon {
  color: #aaa !important;
}
.MuiSvgIcon-root.h-icon.green-icon {
  color: #008009 !important;
}
.type-col {
  max-width: 168px;
  min-width: 168px;
}
.sleeps-col {
  min-width: 60px;
  max-width: 60px;
  .d-flex {
    display: flex;
    flex-wrap: wrap;
  }
  .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.5) !important;
  }
}
.price-col {
  min-width: 140px;
  max-width: 140px;
}
.select-rooms-col {
  min-width: 50px;
  max-width: 50px;
}
//Hotel Footer
.hotelFooter {
  display: flex;
  height: 150px;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
  p {
    color: #000;
  }
}
//Hotel Currency Switch
.react-switch-bg {
  background: #07c !important;
  width: 70px !important;
}
.react-switch.active .react-switch-handle {
  transform: translateX(43px) !important;
}

//Hotel Progress Bar
.hotelProgressBar {
  .MuiStepLabel-label.MuiStepLabel-completed {
    font-weight: 400 !important;
  }
  .MuiStepLabel-label {
    font-weight: 400;
  }
  .active.MuiStep-alternativeLabel .MuiStepLabel-alternativeLabel {
    font-weight: 500 !important;
    color: #000;
  }
  .active.MuiStep-alternativeLabel .MuiSvgIcon-root {
    fill: #0017c2 !important;
  }
  .MuiStepper-root {
    padding: 24px 0 !important;
  }
  .MuiStepButton-root {
    justify-content: flex-start;
  }
  .MuiStepper-root .MuiStep-alternativeLabel:not(:last-child) {
    flex: 4;
  }
  .step1 .stepButton {
    justify-self: flex-end;
  }
  .step1.MuiStep-alternativeLabel {
    flex: unset !important;
  }
  .step0 {
    order: 1;
  }
  .step1 {
    order: 3;
  }
  #divider-progressbar {
    order: 2;
    width: 70%;
    height: 1px;
    top: 10px;
    right: 10px;
    position: relative;
    background: rgba(0, 0, 0, 0.3);
  }
  .MuiStepLabel-root.MuiStepLabel-alternativeLabel {
    flex-direction: row;
  }
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 0;
    margin-left: 5px;
  }
  .MuiStepIcon-root.MuiStepIcon-completed {
    fill: #0017c2 !important;
  }
  .MuiStepIcon-root {
    fill: rgba(0, 0, 0, 0.38);
  }
  .MuiStepConnector-line {
    display: none;
  }
}

//Icon Wrapper
.IconWrapper {
  position: relative;
  > *:first-child {
    width: 100% !important;
  }
  select {
    margin-top: 0 !important;
    appearance: none;
  }
  .icon-div-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-color: transparent;

    .MuiSvgIcon-root {
      margin-right: 3px;
      font-size: 1.2rem;
    }
  }
}

//back button
button.hotel-back-btn ,.hotel-back-btn {
  position: absolute;
  padding: 0 8px !important;
  top: 13px;
  .MuiSvgIcon-root {
    fill: #0d87dd !important;
  }
  span.backText {
    margin-left: 5px;
    font-size: 14px;
    text-transform: capitalize;
    line-height: 18px;
    text-align: left;
    color: #000000;
  }
}
@media (max-width: 780px) {
  .hotelProgressBar {
    display: none !important;
  }
  .HotelSearchResult,
  .HotelDetailPage,
  .hotelCustomerPageContainer,
  .hotelConfirmationPageContainer {
    .homeContainer {
      height: 50px !important;
    }
  }
  .HotelSearchResult,
  .HotelDetailPage,
  .hotelCustomerPageContainer,
  .hotelConfirmationPageContainer {
    .header {
      height: 50px;
      box-sizing: border-box;
    }
    .language-select {
      display: flex;
      align-items: center;
      height: 50px;
    }
  }
}

@media (min-width: 2400px) {
  .hotelCustomerPageContainer {
    display: block !important;
  }
}
