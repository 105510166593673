$searchtextcolor: #a5a7a6;
$white: #fff;

#root .__mm,
#root .__zg {
  font-family: Myanmar3 !important;
}

::-webkit-scrollbar {
  height: 3px;
  width: 3px;
}

body {
  -webkit-overflow-scrolling: touch;
  background-color: #fafafa;
  overflow: overlay;
}

.inWeb.bgKbz .homeCenterInfo {
  height: 130px !important;
  background-color: #0b55a8 !important ;
  z-index: -1;
  margin-top: 0 !important;
}
.btnSearch.btnKbz .btnActive,
.btnSearch.btnKbz .btnDisable {
  background-color: #052a54 !important;
  color: #fff !important;
}
.bgkbz.covidInfo {
  background-color: #0b55a8 !important ;
}
.bgHome {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/6hdxumrc9Yh5b7uUXPAErC/745d7a9ad2781fe745280020f2ead14a/background-desktop-1080-min.webp");
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: cover;
  // background: url("../images/background/four/background-desktop_01.jpg") left
  //     top no-repeat,
  //   url("../images/background/four/background-desktop_02.jpg") right top
  //     no-repeat,
  //   url("../images/background/four/background-desktop_03.jpg") left bottom
  //     no-repeat,
  //   url("../images/background/four/background-desktop_04.jpg") right bottom
  //     no-repeat;
  // background-size: 50.01% 50.01%;
  //background-origin: border-box;
  padding: 0px !important;
  height: 550px;
  //min-height: 100vh;
  min-width: 100%;
  // .homeContainer {
  //   height: 550px !important;
  // }
  .homeCenterInfo {
    height: 130px !important;
    background-color: rgb(0, 174, 255);
    z-index: -1;
    margin-top: 0 !important;
  }
  .homeCenterInfoBlur {
    height: 130px !important;
    background-color: rgba(0, 174, 255, 0.5);
    z-index: -1;
    margin-top: 20px !important;
  }
}
.ageSelect {
  width: 53%;
  float: left;
  margin: 5px 0px;
  .ageType {
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
    font-size: 14px;
  }
  .ageLimit {
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
    font-size: 12px;
    color: #6d8494;
  }
}
.logo {
  // height: 24px;
  // padding-top: 20px;
  height: 39px;
  margin-top: 40px;
  padding-right: 15px;
}
.kbzLogo {
  padding-left: 15px;
  border-left: 2px solid #00aeef;
}
.MuiContainer-root {
  padding: 0px !important;
}
.menu {
  float: left;
  font-family: "Roboto", Myanmar3;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #fff !important;
  padding-right: 27px !important;
  padding-left: 0px !important;
  float: left;
  a {
    font-family: "Poppins", Myanmar3;
    font-weight: 400;
    font-size: 16px !important;
    font-weight: normal !important;
    color: #fff !important;
    text-decoration: none !important;
    width: 100%;
    span,
    img {
      float: left;
      font-size: 18px !important;
    }
  }
}
.menu:hover {
  background-color: transparent !important;
}

.menuList {
  padding: 0px 12px 0px !important;
  margin-top: 20%;
  li.web-flag {
    padding-right: 0px !important;
  }
  li.agent-login {
    position: relative;
    img {
      width: 20px;
      position: absolute;
    }
    .login_title {
      padding-left: 25px;
    }
  }
}
.desktop_login_icon {
  width: 28px;
  padding-left: 30px;
}
.login_title {
  display: none;
}

.menuList ul:focus {
  border-color: transparent;
  outline: none;
}

.bgHome,
#bgBalloon,
#bgBus,
#bgCar,
#bgHotel {
  .menuList li a,
  .menuList li a > span {
    font-size: 16px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
  }
  .menuList li:last-child span {
    padding-bottom: 1px;
  }
  .menuList li:last-child span.myanmarFont {
    padding-bottom: unset;
  }
}
.bgBus .homeContainer {
  // height: 100vh;
  height: auto;
}
.homeCenterInfo {
  font-family: "Poppins", Myanmar3;
  font-weight: 400;
  font-size: 40px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  margin-top: -50px !important;
  position: relative;
  height: 0;
  .divDiscover {
    margin: auto;
    max-width: 610px;
    text-align: right;
  }
}
.homeContainerSearch {
  max-width: 100% !important;
  display: block !important;
  padding: 0px !important;
}
.bgHome {
  .discover {
    font-size: 32px;
    display: none;
  }
  .myanmar {
    font-size: 70px;
    display: none;
  }
}
.myanmar {
  font-family: "Poppins", Myanmar3 !important;
  font-weight: 700;
  font-size: 100px;
  color: #fff;
  line-height: 60px;
  position: relative;
}
.search {
  background-color: #fff;
  height: 74px;
  border-radius: 5px;
  margin-top: -50px !important;
  padding: 0px 0px 0px 30px;
}

.hotelSearchDiv {
  background-color: #fff;
  height: 74px;
  border-radius: 5px;
  // max-width: 1000px;
  padding: 0px 0px 0px 30px;
  margin-top: 20px !important;
}
.busSearch {
  background-color: #fff;
  max-width: 1000px;
  margin: auto;
  height: 74px;
  border-radius: 5px;
  // margin-top: -139px !important;
  // margin-top: -68px !important;
  margin-top: 80px !important;
  padding: 0px 0px 0px 30px;
}
.nopadding {
  padding: 0px !important;
}

.pull-right {
  float: right !important;
}
.searchIcon {
  width: 26px;
  height: 26px;
  margin-top: 24px;
  padding-right: 10px;
  float: left;
}
.btnSearch {
  max-width: 120px !important;
  max-height: 74px !important;
  button {
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
    font-size: 18px !important;
    text-transform: capitalize !important;
    height: 100%;
    border-radius: 0px !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    box-shadow: none !important;
    width: 120px !important;
    margin-right: -1px;
    min-height: 74.5px;
  }
  .btnActive {
    //background-color: rgb(196, 112, 22) !important;
    background-color: #fc9d00 !important;
  }
}
#fl-home-btn-search span {
  pointer-events: none;
}
.MuiFormLabel-root.Mui-focused,
.selectedAiport {
  color: $searchtextcolor !important;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-weight: 500 !important;
  font-size: 16px !important;
}
.MuiFormLabel-root {
  color: $searchtextcolor !important;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.container {
  width: 1200px;
  max-width: 1200px;
  margin: auto;

  .MuiSvgIcon-root {
    color: #00b7f0 !important;
  }
}
.homeContainer {
  // width: 1200px;
  max-width: 1000px;
  display: block;
  // min-width: 1200px;
  //padding: 0px 50px;
  //height: 100vh;
  margin: auto;
  .DateInput {
    width: 100% !important;
  }
  .DateInput_input {
    font-size: 16px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
    color: #231f20 !important;
    width: 95% !important;
    padding: 7px 11px 9px;
    float: left;
  }
  .flightSearch .DateInput_input {
    font-size: 18px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
    color: #231f20 !important;
    width: 90% !important;
    padding: 7px 11px 9px;
    float: left;
  }
  .DateInput__disabled {
    width: 0px !important;
  }
  .DateInput_1 {
    #start_date_id {
      position: inherit;
      padding: 4px 13px 7px 7px;
      min-width: 200px;
    }
    #end_date_id {
      padding: 7px 22px 7px 0px;
      margin: auto;
      margin-top: 5px;
      width: 0px !important;
    }
  }
  .flightSearch .DateInput_1 {
    #start_date_id {
      padding: 10px 2px 7px 7px !important;
      min-width: 130px !important;
    }
  }
  .flightSearch .DateInput_1 {
    #return_start_date_id {
      padding: 10px 2px 7px 7px !important;
    }
  }
  .selected #end_date_id {
    margin-top: -10px !important;
  }
  .selected .DateRangePickerInput {
    padding-top: 0px !important;
  }

  .DateInput_input::placeholder {
    font-size: 16px !important;
    font-weight: normal !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
    color: $searchtextcolor !important;
    padding: 0px 11px 9px;
  }

  .DateRangePickerInput,
  .DateInput_input__focused {
    border: none !important;
    width: 100%;
    padding-top: 13px;
  }
  .DateInput {
    line-height: 40px !important;
    // width: 50% !important;
    float: left;
  }
}

.hotelContainer {
  // width: 1200px;
  //max-width: 1000px;
  //display:block;
  // min-width: 1200px;
  //padding: 0px 50px;
  //height: 100vh;
  // margin: auto;
  .DateInput {
    width: 100% !important;
  }
  .DateInput_input {
    font-size: 16px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
    color: #231f20 !important;
    width: 95% !important;
    padding: 7px 11px 7px;
    float: left;
  }
  .flightSearch .DateInput_input {
    font-size: 18px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
    color: #231f20 !important;
    width: 90% !important;
    padding: 7px 11px 9px;
    float: left;
  }
  .DateInput__disabled {
    width: 0px !important;
  }
  .DateInput_1 {
    #start_date_id {
      position: inherit;
      padding: 7px 13px 7px 7px;
      min-width: 200px;
    }
    #end_date_id {
      padding: 7px 22px 7px 0px;
      margin: auto;
      margin-top: 5px;
      width: 0px !important;
    }
  }
  .flightSearch .DateInput_1 {
    #start_date_id {
      padding: 10px 2px 7px 7px !important;
      min-width: 130px !important;
    }
  }
  .flightSearch .DateInput_1 {
    #return_start_date_id {
      padding: 10px 2px 7px 7px !important;
    }
  }
  .selected #end_date_id {
    margin-top: -10px !important;
  }
  .selected .DateRangePickerInput {
    padding-top: 0px !important;
  }

  .DateInput_input::placeholder {
    font-size: 16px !important;
    font-weight: normal !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
    color: $searchtextcolor !important;
    padding: 0px 11px 9px;
  }

  .DateRangePickerInput,
  .DateInput_input__focused {
    border: none !important;
    width: 100%;
    padding-top: 13px;
  }
  .DateInput {
    line-height: 40px !important;
    // width: 50% !important;
    float: left;
  }
}
.DateRangePicker {
  width: 80%;
  height: 30px;
}
.DateInput_fang {
  bottom: 70px !important;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover,
.CalendarDay__default:hover {
  background: #00aeef !important;
  border: none !important;
  color: #fff !important;
  border-radius: 3px;
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover,
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:active,
.CalendarDay__hovered_span:hover,
.CalendarDay__selected_span {
  background: #e5f7fb !important;
  border: none !important;
  color: #000 !important;
}
.CalendarDay__default {
  border: none !important;
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff !important;
  color: #cacccd !important;
  border: none !important;
}
.DateRangePickerInput {
  height: 10px;
}
.DateInput {
  width: 139px !important;
}
.DateRangePickerInput__disabled,
.DateInput_input__disabled,
.DateInput__disabled {
  background: #fff !important;
  display: none;
}
.DateRangePickerInput__withBorder {
  border: none !important;
}
.wrapper {
  //margin-top: -7px;
}
.hotelMyanmarCitizen {
  width: 30%;
  float: left;
  height: 34px;
  margin-top: -36.5px;
  position: initial;
  margin-left: 160px;
  //background-color: rgba(0, 0, 0, 0.5);
  background-color: #fff;

  padding: 0px 10px;
  //border-top-left-radius: 5px;
  //border-top-right-radius: 5px;
  span {
    color: #656565;
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
    font-size: 14px;
    margin: auto;
  }
  .MuiFormControlLabel-root {
    margin-left: 5px;
    margin-right: 5px !important;
  }
  .MuiSvgIcon-root {
    font-size: 1rem !important;
    // color: #fff !important;
    color: #00aeef !important;
  }
}
.MuiFormControlLabel-root.Mui-disabled {
  cursor: not-allowed !important;
  color: #e4e4e4 !important;
}
.myanmarCitizen {
  width: 30%;
  float: left;
  margin-top: -36.5px;
  position: initial;
  //margin-left: 160px;
  //background-color: rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-right: 1px solid #e4e4e4;
  border-top-left-radius: 4px;

  padding: 0px 10px;
  //border-top-left-radius: 5px;
  //border-top-right-radius: 5px;
  span {
    color: #656565;
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
    font-size: 14px;
    margin: auto;
  }
  .MuiFormControlLabel-root {
    margin-left: 5px;
    margin-right: 5px !important;
  }
  .MuiSvgIcon-root {
    font-size: 1rem !important;
    // color: #fff !important;
    color: #00aeef !important;
  }
}
.bgKbz .myanmarCitizen .MuiSvgIcon-root,
.bgKbz .MuiRadio-colorSecondary.Mui-checked .MuiSvgIcon-root,
.bgKbzContainer .myanmarCitizen .MuiSvgIcon-root {
  font-size: 1rem !important;
  // color: #fff !important;
  color: #0b55a8 !important;
}
.passengerType {
  width: 30%;
  float: left;
  margin-top: -36.5px;
  position: initial;
  margin-left: 160px;
  //background-color: rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-right: 1px solid #e4e4e4;
  border-top-right-radius: 4px;

  padding: 0px 10px;
  //border-top-left-radius: 5px;
  //border-top-right-radius: 5px;
  span {
    color: #656565;
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
    font-size: 14px;
    margin: auto;
  }
  .MuiFormControlLabel-root {
    margin-left: 5px;
    margin-right: 5px !important;
  }
  .MuiSvgIcon-root {
    font-size: 1rem !important;
    // color: #fff !important;
    color: #00aeef !important;
  }
}
#tripType {
  min-width: 130px;
  cursor: pointer;
}
.cities-popup.return-popup {
  left: 0% !important;
  padding: 5px !important;
  .MuiGrid-item {
    padding: 0px !important;
  }
  span {
    padding: 10px !important;
    display: block;
  }
}
div[id^="__lpform_"] {
  display: none;
}
@media (min-width: 1280px) {
  .MuiContainer-root {
    padding: 0px !important;
  }
}
@media (min-width: 800px) {
  .MuiContainer-root {
    padding: 0px !important;
  }
  .myanmarCitizen {
    max-width: 250px !important;
    height: 34px;
  }
  .hotelMyanmarCitizen {
    max-width: 250px !important;
  }
}
.searchBox {
  #fromAirport,
  #toAirport {
    height: 1.3875em !important;
  }
}
.passengerType {
  margin-left: 0px !important;
  // margin-left: 10px !important;
  padding: 5px 10px !important;
  width: auto !important;
  height: 24px;
  border-right: 1px solid #e4e4e4;
}
.MuiInputBase-input {
  padding: 0px !important;
}
.Mui-focused,
.MuiFormLabel-filled {
  line-height: 36px !important;
}
.MuiRadio-colorSecondary.Mui-checked {
  .MuiSvgIcon-root {
    color: #01b7f2 !important;
  }
}
.MuiSvgIcon-root,
.MuiFormControlLabel-label {
  //color: #656565 !important;
}
.swapDestination {
  color: #707070 !important;
}
.focusedCalendar {
  margin-top: -15px;
}
.DateRangePickerInput_calendarIcon {
  padding: 0px !important;
  margin: auto !important;
}
.bgHomeService {
  background-color: #ffffff;
  .homeContainer {
    height: auto !important;
    max-width: unset;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }
}
.busHomeService {
  // margin-top: 132%;
  .homeContainer {
    height: auto !important;
    max-width: unset;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }
}

.book-now-button {
  width: 200px !important;
  height: 50px !important;
  display: block !important;
  margin: 40px auto 0 !important;
  border: solid 2px #f7696b !important;
  border-radius: 25px !important;

  span {
    font-family: "Roboto", Myanmar3 !important;
    font-weight: bold;
    color: #f7696b !important;
    text-transform: capitalize;
  }
}
.website-ads {
  width: 130%;
}
@media (min-width: 1300px) {
  .airport-popup {
    margin-left: 20% !important;
  }
}
@media (max-width: 780px) {
  .passengerType {
    display: none !important;
  }
  .newMenuList {
    display: none !important;
  }
  .footer-socialDiv {
    width: 170px;
    text-align: center;
    margin: auto;
    display: block !important;
  }
  .footer-app .grid-footer:first-child {
    width: 170px;
    margin: auto;
    display: block;
    text-align: center;
  }
  .appDownload {
    a {
      display: block;
    }
    a img {
      margin-right: 0;
    }
    text-align: center;
    flex-direction: column;
    justify-content: center;
  }
  .website-ads {
    height: 170px;
    width: 300px !important;
  }
  .footer-social-media {
    margin: auto !important;
  }
  .MuiCard-root.confirmationBox.covidAlertBox {
    top: 50% !important;
    max-width: 100vw !important;
    left: 0% !important;
  }
  .MuiCard-root.covidAlertBox.MuiPaper-elevation1 {
    box-shadow: none !important;
  }
}
@media (min-width: 781px) {
  .bgHome {
    // removed margin-top for covid banner
    margin-top: 35px;
  }
  .myanmarCitizen,
  .passengerType {
    width: unset;
  }
  .hotelMyanmarCitizen {
    width: unset;
  }
  .searchBox {
    flex-wrap: nowrap;
    display: flex;
    justify-content: flex-start;
  }
}
@media (min-width: 800px) {
  .swapDestination {
    margin-top: 25px !important;
    margin-left: -40px !important;
    // position: absolute;
    float: left;
    cursor: pointer;
  }
  .flightSearch .swapDestination {
    margin-top: 25px !important;
    margin-left: -19px !important;
    // position: absolute;
    float: left;
    cursor: pointer;
  }
  .myanmarCitizen,
  .passengerType {
    width: unset !important;
  }
  .hotelMyanmarCitizen {
    width: unset !important;
  }
  .hotelSearchDiv {
    .searchRadioGroup {
      display: flex;
      justify-content: flex-start;
      position: relative;
      bottom: 1px;
      margin-left: 45px;
    }
  }
  .search {
    .searchRadioGroup {
      display: flex;
      justify-content: flex-start;
      position: relative;
      bottom: 1px;
    }
  }
}
.airport-popup .MuiGrid-item.airportListTitle {
  font-weight: bold;
  pointer-events: none;
}
.airport-popup {
  width: 700px !important;
}
.home.airport-popup {
  width: 950px !important;
}
.airport-popup,
.cities-popup {
  width: 600px;
  margin-left: 12%;
  padding: 10px;
  margin-bottom: 15px;
  position: relative;
  .MuiGrid-item {
    padding: 7px 12px !important;
    font-size: 14px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
  }
}

.cities-popup {
  .MuiGrid-item:hover {
    color: #00aeef;
    cursor: pointer;
  }
}
.airport-popup {
  margin-left: 0% !important;
}

.top.airport-popup:after,
.top.cities-popup:after {
  content: "";
  transform: rotate(-45deg);
  background: #01b7f2;
  position: absolute;
  border: 1px solid #e6e7e8;
  z-index: -1;
  left: 15%;
  margin-left: -12px;
  width: 26px;
  height: 14px;
  overflow: hidden;
  margin-top: -3px;
}
.top.cities-popup.return-popup:after {
  margin-top: -7px !important;
}
.bottom.airport-popup:after,
.bottom.cities-popup:after {
  content: "";
  width: 26px;
  height: 14px;
  transform: rotate(-45deg);
  background: #01b7f2;
  position: absolute;
  border: 1px solid #e6e7e8;
  z-index: -1;
  top: -3px;
  left: 15%;
  position: absolute;
}
.cities-popup {
  width: 200px !important;
  left: -30% !important;
  margin-left: 0% !important;
}

.flightType {
  background-color: #ebecf0;
  color: #231f20;
  border-radius: 4px;
  padding: 7px 25px;
  width: 80px;
  float: left;
  font-size: 14px;
  line-height: 15px;
  font-family: "Roboto", Myanmar3;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;
}
.flightType.active {
  background-color: #01b7f2 !important;
  color: #fff !important;
}
.flightType.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.MuiInputBase-root {
  color: #231f20 !important;
}
.selectedAirport {
  color: #000000 !important;
  line-height: 30px;
  padding-left: 10px;
  line-height: 30px;
  padding-left: 10px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 700;
  font-size: 16px;
}
.MuiInput-underline:before,
.MuiInput-underline:after {
  border-bottom: none !important;
}
.divFeaturedIn {
  background-color: #f7f7f7 !important;
  padding-top: 30px !important;
  margin-top: 0px !important;
  padding-bottom: 40px;
  background-color: #f7f7f7;
  .container {
    width: 100%;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }
  .col {
    padding: 0 5px;
    margin: 0 auto;
  }
}
.featuredInContainer {
  margin-top: 25px;
}
.divAirlinePartner {
  padding-top: 30px !important;
  background-color: #f7f7f7;

  //background-image: url("../images/airline-bg.jpg");
}
.busAirlinePartner {
  padding-top: 30px !important;
  background-color: #f7f7f7f7;
  // margin-top: 52px !important;
  margin-top: 0% !important;
}
.titleSlider {
  // font-weight: normal;
  color: #00b7f0;
  // font-size: 1.267em !important;
  // line-height: 50px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: bold;
  font-size: 16px;
  display: block;
}
.airlinePartner,
.airlineFeatured {
  display: block;
  text-align: center;
  font-size: 30px !important;
  font-family: "Poppins", Myanmar3 !important;
  font-weight: 500 !important;
  color: #000;
}
.footer {
  color: white;
  background-color: #2e2e2e;
  min-height: 50px;
  & > .container {
    margin: 0;
    width: 100%;
    max-width: unset;
  }
  .container .MuiGrid-container {
    max-width: 1200px;
    width: 100%;
    margin: 40px auto 0px;
    justify-content: space-around;
  }
  .titleSlider {
    margin-bottom: 20px;
  }
}
.footer-gridbox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-top: 1px solid white;
  font-family: "Roboto", Myanmar3;
  font-size: 16px;
  font-weight: bold;
}
.grid-footer a {
  &:hover {
    color: #00b7f1;
  }
}
.grid-footer:first-child,
.grid-footer:nth-child(2),
.grid-footer:nth-child(3),
.grid-footer:nth-child(4) {
  margin-bottom: 50px;
}
.grid-footer:last-child {
  justify-content: center;
  .titleSlider {
    text-align: center;
  }
}
.grid-footer:nth-child(4) {
  & > div > img {
    width: 180px;
    padding: 15px 0 15px;
  }
  & > div > div {
    display: flex;
    div:first-child {
      margin-right: 15px;
    }
  }
  & div > div span {
    display: block;
    &:first-child {
      font-size: 14px;
      padding-bottom: 5px;
    }
    &:not(:first-child) {
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.grid-footer:last-child {
  // > div {
  //   width: 170px;
  // }
  display: flex;
  width: 230px;
}
.grid-footer:nth-child(3) {
  display: flex;
  width: 230px;
}
.footer-bar {
  display: flex;
  align-items: center;
  .MuiFormControl-marginNormal {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (max-width: 1023px) {
}
//Medium break points
@media (max-width: 960px) {
  .divAirlinePartner .bgWhite {
    flex-wrap: wrap;
    justify-content: center;
    div {
      //width: 30%;
    }
  }
  .busAirlinePartner .bgWhite {
    flex-wrap: wrap;
    justify-content: center;
    div {
      width: 30%;
    }
  }
  .footer .container .MuiGrid-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 959px) {
  .grid-footer:nth-child(4) > div > img {
    padding-top: 0 !important;
  }
  .grid-footer:last-child {
    justify-content: flex-start;
    .titleSlider {
      text-align: left;
    }
  }
}

.footer-textfield input {
  width: 230px;
  height: 45px;
  border: 1px solid #2e2e2e;
  border-radius: 25px;
  background: white;
  outline: none;
  padding-left: 15px !important;
  margin-right: 15px;
}
.footer-subscribe {
  height: 45px;
  width: 120px;
  vertical-align: middle;
  border-radius: 25px !important;
  border: 1px solid white !important;
  margin-top: 5px;
  span {
    color: white;
    text-transform: capitalize;
  }
}
.footer-bottom {
  height: 230px;
  display: inline-block;
  overflow: hidden;
  td {
    font-size: 12px;
    font-family: "Roboto", Myanmar3 !important;
    a {
      color: #fff !important;
    }
    a:hover {
      color: #94dbeb !important;
    }
  }
  .row {
    display: flex;
  }
  .col {
    padding-right: 10px;
    padding-bottom: 10px;
    img {
      width: 70px;
    }
  }
}
.footer-social-media {
  display: flex;
  justify-content: space-around;
  max-width: 150px;
  margin: 0;
  img[src*="linkedin"] {
    height: 44px;
    width: 44px;
    margin-top: -1px;
    margin-left: -2px;
  }
}
.bgWhite {
  background-color: $white;
}

.divAirlinePartner {
  .container {
    width: 100%;
  }
  .bgWhite {
    display: flex;
    justify-content: space-around;
    padding: 0 75px;
    margin-bottom: 20px;
    // margin-top: 60px;
    margin-top: 30px;
    background-color: #f7f7f7;
  }
}
.busAirlinePartner {
  .container {
    width: 100%;
  }
  .bgWhite {
    display: flex;
    justify-content: space-around;
    padding: 0 75px;
    margin-bottom: 20px;
    //margin-top: 5px;
    margin-top: 60px;
    background-color: #f7f7f7;
  }
}
.maillist {
  color: $white;
  font-size: 14px;
  font-weight: normal;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-style: italic;
}
.contact-details {
  padding-bottom: 20px;
  padding-top: 10px;
}
.departfrom {
  width: calc(33.3333% - 39.99996px);
  max-width: calc(33.3333% - 39.99996px) !important;
  min-width: calc(33.3333% - 39.99996px);

  .MuiTextField-root {
    margin-top: 20px !important;
    width: 100%;
  }
  .MuiInputBase-root {
    font-size: 16px !important;
    font-weight: bold !important;
    font-family: "Roboto", Myanmar3 !important;
    margin-top: 15px !important;
    label {
      margin-top: 5px;
    }
  }
  .MuiInputLable-animated {
    margin-top: -5px !important;
  }
}
@media (min-width: 780px) {
  .flightSearch .departfrom {
    // max-width: calc(33.34% - 40px) !important;
    // min-width: calc(33.34% - 40px) !important;
    max-width: calc(33.36% - 40px) !important;
    min-width: calc(33.36% - 40px) !important;
    max-height: 73px !important;
  }
  .flightSearch .btnSearch {
    margin-top: -1px !important;
  }
  .flightSearch.hasReturn .departfrom {
    max-width: calc(25% - 30px) !important;
    min-width: calc(25% - 30px) !important;
  }
  .flightSearch.return .departfrom {
    max-width: calc(33.36% - 40px) !important;
    min-width: calc(3.36% - 40px) !important;
    max-height: 73px !important ;
  }
  .flightSearch.hasReturn .btnSearch {
    margin-top: -0.8px !important;
  }
}
.returnTrip {
  padding-left: 10px !important;
}
.MuiFormControl-root {
  label {
    margin-top: -12px;
  }
}
.MuiFormControl-root {
  .MuiInputLabel-shrink {
    transform: translate(0, -5.5px) scale(0.85) !important;
  }
}
.dividerAiport {
  height: 10px;
  padding: 10px 0px;
}
.departureDate {
  font-size: 12px;
  font-family: "Roboto", Myanmar3;
  font-weight: 400;
  color: #a5a7a6 !important;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.busOperatorLogo {
  .imgContainer {
    display: inline-block;
    width: 184px !important;
    max-width: 184px !important;
    img {
      max-width: 100%;
      width: 100% !important;
      margin: -15px 0px;
    }
  }
}
.block {
  display: block !important;
  font-size: 12px;
  font-family: "Roboto", Myanmar3;
  font-weight: 400;
  color: #a5a7a6 !important;
  opacity: 1;
  transform: translate(0, 1.5px) scale(1);
  transform-origin: top left;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  margin-top: 8px;
}
.departFlight {
  font-family: "Poppins", Myanmar3 !important;
  font-weight: 400;
  float: left;
  margin-left: 8px;
  width: 37%;
  min-width: 100px;
  margin-top: 6px;
  max-height: 30px;
  height: 22px;
}
.returnFlight {
  float: right;
  margin-left: 50px;
  width: 45%;
}
.hide {
  display: none !important;
}
.mmBusLogo img,
.mmBusLogo span {
  font-size: 12px !important;
  float: right !important;
}
.mmBusLogo img {
  width: 50px !important;
  // New MM Bus Ticket Logo adjustments
  margin-top: 18px;
  margin-left: 5px;
}
.mmBusLogo span {
  //color: #fff;
  color: #000;
  font-weight: bold;
  line-height: 70px !important;
}
@media (min-width: 700px) {
  .mobile-from-to {
    display: none !important;
  }
}
.DateInput_input__focused::placeholder {
  opacity: 0 !important;
}
.alice-carousel__stage-item {
  opacity: 0.5;
  width: 200px;
}
.alice-carousel__stage-item:hover {
  opacity: 1 !important;
}
.airlineImg {
  width: 184px;
  height: 61px;
  padding: 20px 0;
}
.pull-left {
  float: left;
}
.clickAwayDiv {
  width: 100%;
  float: left;
  height: 100%;
}
.flightSearch .clickAwayDiv {
  width: 88% !important;
}
.arrow-left {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  position: absolute;
  top: 28px;
  line-height: 0.78;
  left: 25px;
  border-right: 9px solid #4b515d;
}
.arrow-right {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  position: absolute;
  top: 28px;
  line-height: 0.78;
  right: 25px;
  border-left: 9px solid #4b515d;
}
.DayPicker_weekHeader {
  font-family: "Roboto", Myanmar3;
  font-weight: 400;
  font-size: 14px !important;
}
.DateRangePicker_picker {
  border-radius: 5px;
  left: -180px !important;
  bottom: 62px !important;
  z-index: 1 !important;
}
.checkReturnDate {
  margin-top: 13px !important;
  margin-left: 240px !important;
  z-index: 999;
  position: absolute !important;
  height: auto !important;
}
.selectedCheckReturn {
  margin-top: 23px !important;
  margin-left: 240px !important;
  z-index: 999;
  position: absolute !important;
  height: auto !important;
}
.btnBlueSearch {
  display: none !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.pull-left {
  float: left;
}
.homeMenu,
.chooseReturnDate {
  opacity: 0 !important;
}
.datepicker {
  margin: auto;
  max-height: 74px;
  position: relative;
}
.textRight {
  text-align: right !important;
}
.dateRange {
  width: -webkit-calc(0.333333 * (100% - 120px));
  width: -moz-calc(0.333333 * (100% - 120px));
  width: calc(0.333333 * (100% - 120px));
  max-width: calc(0.333333 * (100% - 120px)) !important;
  min-width: calc(0.333333 * (100% - 120px));
  //margin: auto 0px !important;
}

.DateRangePickerInput_arrow {
  display: none !important;
}
.calendarIcon {
  // position: absolute;
  z-index: 30;
  line-height: 50px;
  margin: 24px 0px;
  width: 26px;
  height: 26px;
  float: left;
}
.flightSearch .calendarIcon {
  margin: 22px 0px;
}
.selected-calendar {
  margin-top: 24px !important;
}
.dividerContainer {
  margin-top: 5px !important;
}
.loadingWrapper {
  margin: 20px auto;
  width: 889px !important;
  text-align: left;
  background-color: #fff;
  padding: 20px;
}
.btnDisable {
  //background-color: rgb(196, 112, 22) !important;
  background-color: #fc9d00 !important;
  pointer-events: none;
}

.noborder {
  border: none !important;
}
.airport-popup {
  ::-webkit-scrollbar {
    width: 3px;
  }
  .scroll {
    background-color: #fff;
    width: 100%;
    overflow-y: scroll;
  }
  .scroll::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
  }
  .scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
    height: 30px;
  }
  .scroll::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 10px;
  }
  // ::-webkit-scrollbar-track {
  //     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  // }

  // ::-webkit-scrollbar-thumb {
  //   background-color: darkgrey;
  //   outline: 1px solid slategrey;
  // }
  scrollbar-width: normal !important;
  scrollbar-color: red !important;
  .MuiGrid-container {
    // display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(8, auto);
    // height: auto !important;
    height: 300px !important;
    overflow: auto;
    scrollbar-width: normal !important;
    scrollbar-color: red !important;
    ::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: red;
    }
    ::-webkit-scrollbar-track {
      background-color: green;
    }
    .MuiGrid-container ::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }
    .MuiGrid-grid-xs-4 {
      max-width: 100% !important;
    }
  }
}
// .airport-popup-auto{
//   .MuiGrid-container {
//   //height: auto !important;
//   }
// }

.inAppHeaderLogo {
  width: 150px;
  display: block;
  margin-left: 15px;
}
.pageInAppError {
  width: 90%;
  height: 100vh;
  display: block;
  margin: 0 auto !important;
  text-align: center;
  .title {
    font-family: "Roboto", Myanmar3;
    font-weight: 700;
    font-size: 40px;
  }
  button {
    font-family: "Roboto", Myanmar3;
    background-color: #2196f3;
    padding: 10px 15px;
    color: #fff;
    margin-left: 20px;
  }
}
.page404 {
  width: 90%;
  height: 100vh;
  display: block;
  margin: 0 auto !important;
  text-align: center;
  .title {
    font-family: "Roboto", Myanmar3;
    font-weight: 700;
    font-size: 80px;
  }
  .warning {
    font-family: "Roboto", Myanmar3;
    font-weight: 500;
    font-size: 30px;
    color: #919191;
    display: inline-block;
    margin-bottom: 30px;
  }
  button {
    font-family: "Roboto", Myanmar3;
    background-color: #2196f3;
    padding: 10px 15px;
    color: #fff;
    margin-left: 20px;
  }
  button:hover {
    background-color: #2196f3;
  }
}

.maintenancePage {
  width: 90%;
  height: 100vh;
  display: block;
  margin: 0 auto !important;
  text-align: center;
  .flymyaLogo {
    width: 200px;
    margin-top: 30px;
  }
  .spaceDiv {
    height: 100px;
  }
  .maintenanceLogo {
    width: 120px;
  }
  .firstLine {
    font-family: "Poppins";
    font-size: 30px;
    font-weight: 600;
  }
  .secondLine {
    font-family: "Roboto";
    font-size: 18px;
    font-weight: 400;
  }
}

.pageHeaderLogo {
  width: 150px;
  display: block;
  img {
    height: 24px;
  }
}
.CalendarDay__default.CalendarDay__today {
  color: #0041ff !important;
  border: 2px solid #a7a7a7 !important;
  border-radius: 3px;
}
.MuiMenuItem-root {
  overflow: inherit !important;
  .searchBox {
    .MuiInputBase-input {
      width: 90% !important;
    }
  }
}
.languageDiv {
  box-shadow: 0 5px 11px #ccc;
  border: 1px solid hsla(0, 0%, 50.2%, 0.08);
  text-align: center !important;
  padding: 0rem 0.5rem !important;
}
.languageButtonEng {
  text-align: center;
}
.languageButton {
  text-align: center;
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.languageButtonMya {
  margin-right: 1rem !important;
}
.disableButton {
  opacity: 0.3 !important;
  cursor: default !important;
}
.myanmarFont,
span,
label,
.MuiFormLabel-root,
.departFlight {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
}
.homeAgeSelect {
  width: 90%;
  float: left;
  margin: 5px 0px;
}
.pasengerTypeCard {
  max-width: 260px;
  padding: 20px;
  left: 25%;
  z-index: 1000;
  .ageSelect {
    width: 90% !important;
  }
}
.react-numeric-input b:nth-child(2) i {
  background-color: #23af2e !important;
}
.react-numeric-input b:last-child i {
  background-color: #ff5757 !important;
}
.homeAgeSelect {
  width: 90%;
  float: left;
  margin: 5px 0px;
}
.pasengerTypeCard {
  max-width: 260px;
  padding: 20px;
  left: 25%;
  z-index: 1000;
  .ageSelect {
    width: 90% !important;
  }
}
.homeChooseAge {
  width: 100%;
  float: right;
  margin: 10px 0px;
  .react-numeric-input {
    width: 100%;
    padding-left: 2px;
    height: 30px;
  }

  .numeric-counter {
    padding: 0px !important;
    height: 30px;
    width: 106px;
    border: 1px solid #c7c7c7 !important;
  }

  b {
    background-color: #fff !important;
    border: none !important;
    border-right: 1px solid #c7c7c7 !important;
    border-left: 1px solid #c7c7c7 !important;
    width: 4.3ex !important;
    cursor: pointer !important;
  }
}
.passengerTooltip {
  z-index: 1000 !important;
}

@media (min-width: 800px) and (max-width: 1000px) {
  .myanmarCitizen {
    width: 250px;
  }
  .hotelMyanmarCitizen {
    width: 250px;
  }
}
.selected--flag--option,
.arrow-down {
  color: #fff !important;
}
.flag-select {
  margin-top: 5px;
  color: #888;
}
.homeFlag {
  float: right !important;
}
#bgBus .flag-select__btn,
#bgCar .flag-select__btn,
#bgHotel .flag-select__btn,
#bgBalloon .flag-select__btn,
.bgHome .flag-select__btn {
  color: #fff !important;
  padding-left: 0px;
  margin-left: -5px;
}
.homeFlag > .selected--flag--option {
  font-size: 16px !important;
  color: #fff !important;
}
#homeFlag .flag-select {
  float: right !important;
  margin-top: 3px !important;
}
#homeFlag .flag-select .selected--flag--option {
  font-size: 16px !important;
  color: #fff !important;
}
#homeFlag .flag-select .arrow-down {
  color: #fff !important;
}
#homeFlag button:focus {
  border-color: transparent;
  outline: none;
}
.__react_component_tooltip {
  z-index: 99999 !important;
}
//TABLE
@media (min-width: 769px) and (max-width: 1023px) {
  .homeContainer {
    // .MuiGrid-item:first-child {
    //   #homeFlag .flag-select {
    //     display: none;
    //   }
    //   button.menuIcon.nopadding {
    //     display: none;
    //   }
    // }
    .menuList {
      position: absolute;
      right: 0;
    }
  }
}
@media (min-width: 800px) {
  .deptAirportImgDiv {
    background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4G7sU5i6rTKBralqLeCZAr/62c55efdfc0ea1f529bc6d6efbf488a9/icon-sprite-min.png");
    background-position: 1px -91px;
    background-size: 25px;
    width: 24px;
    height: 24px;
    float: left;
    margin-top: 28px;
    margin-right: 15px;
  }
  .arrivAirportImgDiv {
    background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4G7sU5i6rTKBralqLeCZAr/62c55efdfc0ea1f529bc6d6efbf488a9/icon-sprite-min.png");
    background-position: 1px -117px;
    background-size: 25px;
    width: 24px;
    height: 24px;
    float: left;
    margin-top: 28px;
    margin-right: 15px;
  }
  .flightSearch .arrivAirportImgDiv {
    margin-left: 5px !important;
  }
  .calendarImgDiv {
    background-image: url("../images/sprite-images.png");
    background-position: 1px 35px;
    background-size: 25px;
    width: 24px;
    height: 24px;
    float: left;
    margin-top: 28px;
    // margin-right: 15px;
  }
  .down-arrow-img {
    background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4G7sU5i6rTKBralqLeCZAr/6f3637e8be7652b1a57b94218e0a9e7c/icon-sprite-min.png");
    background-position: 143px -27px;
    background-size: 220px;
    width: 24px;
    height: 24px;
    float: right;
    margin-left: 5px;
  }
  .allday-img {
    background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4G7sU5i6rTKBralqLeCZAr/6f3637e8be7652b1a57b94218e0a9e7c/icon-sprite-min.png");
    background-position: -6px -77px;
    background-size: 187px;
    width: 58px;
    height: 49px;
    float: right;
    margin-right: 5px !important;
  }
}
.MuiDialog-paperWidthSm {
  min-width: 70% !important;
  position: relative !important;
}
.MuiDialogTitle-root {
  padding: 5px 12px !important;
}
.Component-closeButton-* {
  top: -8px !important;
  right: -5px !important;
  position: relative !important;
}
.offlineCloseIcon {
  top: -3px !important;
}

/*feature image sprite*/
.feature-img-irrawady-burma {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: 12px -10px;
  background-size: 1000px;
  width: 105px;
  height: 65px;
  float: left;
}
.feature-img-goaway {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -239px -12px;
  background-size: 1000px;
  width: 100px;
  height: 65px;
  float: left;
}
.feature-img-honeycamber {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -333px -12px;
  background-size: 1000px;
  width: 100px;
  height: 65px;
  float: left;
}
.feature-img-ncra {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -397px -93px;
  background-size: 1000px;
  width: 135px;
  height: 65px;
  float: left;
}
.feature-img-mizzima-burma {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: 10px -93px;
  background-size: 1000px;
  width: 105px;
  height: 65px;
  float: left;
}
.feature-img-malay-mail-online {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -568px -26px;
  background-size: 1000px;
  width: 115px;
  height: 65px;
  float: left;
}
.feature-img-sd-asia {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -563px -96px;
  background-size: 1000px;
  width: 108px;
  height: 65px;
  float: left;
}
.feature-img-today {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -853px -96px;
  background-size: 1000px;
  width: 81px;
  height: 65px;
  float: left;
}
.feature-img-mizzima-eng {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -816px -16px;
  background-size: 976px;
  width: 127px;
  height: 65px;
  float: left;
}
.feature-img-mingalapar {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -698px -25px;
  background-size: 1000px;
  width: 119px;
  height: 65px;
  float: left;
}

.feature-img-myan-biz-today {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -254px -86px;
  background-size: 960px;
  width: 103px;
  height: 65px;
  float: left;
}
.feature-img-coconut-bkk {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -402px -163px;
  background-size: 1000px;
  width: 90px;
  height: 65px;
  float: left;
}
.feature-img-coconut-ygn {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -135px -158px;
  background-size: 1000px;
  width: 90px;
  height: 65px;
  float: left;
}
.feature-img-yangon-life {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -259px -158px;
  background-size: 1000px;
  width: 137px;
  height: 65px;
  float: left;
}
.feature-img-irrawaddy {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -450px -17px;
  background-size: 1000px;
  width: 110px;
  height: 65px;
  float: left;
}
.feature-img-travel-leisure {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -10px -155px;
  background-size: 1000px;
  width: 100px;
  height: 65px;
  float: left;
}
.feature-img-myanmar-times {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -126px -89px;
  background-size: 1000px;
  width: 142px;
  height: 65px;
  float: left;
}
.feature-img-new-light {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -126px -12px;
  background-size: 1000px;
  width: 122px;
  height: 65px;
  float: left;
}
.feature-img-tech-in-asia {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -684px -98px;
  background-size: 1000px;
  width: 142px;
  height: 65px;
  float: left;
}
/*feature image sprite*/

/*airline image sprite*/
.airline-gma {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/2c38yH74IN2IQi1nznIWZd/c81ce0a40df17bf6ad68f9d63d5745df/airlines_new.png");
  // background-position: -200px -67px;
  background-position: 1px -85px;
  background-size: 391px;
  width: 184px;
  height: 75px;
  float: left;
  margin-left: 20px;
}
.airline-airkbz {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/2c38yH74IN2IQi1nznIWZd/c81ce0a40df17bf6ad68f9d63d5745df/airlines_new.png");
  // background-position: -18px -144px;
  background-position: 183px -1px;
  background-size: 419px;
  width: 184px;
  height: 75px;
  float: left;
}
.airline-yangon-airway {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/2c38yH74IN2IQi1nznIWZd/c81ce0a40df17bf6ad68f9d63d5745df/airlines_new.png");
  background-position: -4px 7px;
  //background-position: -4px 7px;
  background-size: 400px;
  width: 184px;
  height: 75px;
  float: left;
}
.airline-mna {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/2c38yH74IN2IQi1nznIWZd/c81ce0a40df17bf6ad68f9d63d5745df/airlines_new.png");
  background-position: -203px 74px;
  background-size: 416px;
  width: 184px;
  height: 75px;
  float: left;
  margin-top: -8px;
}
.airline-mna-mobile {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/2c38yH74IN2IQi1nznIWZd/c81ce0a40df17bf6ad68f9d63d5745df/airlines_new.png");
  background-position: -370px 74px;
  background-size: 390px;
  width: 184px;
  height: 74px;
  float: left;
  display: none;
  margin-top: -16px;
}
.airline-myp {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/2c38yH74IN2IQi1nznIWZd/c81ce0a40df17bf6ad68f9d63d5745df/airlines_new.png");
  // background-position: -16px -280px;
  background-position: 183px -79px;
  background-size: 444px;
  width: 184px;
  height: 75px;
  float: left;
}
.airline-air-thanlwin {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/2c38yH74IN2IQi1nznIWZd/c81ce0a40df17bf6ad68f9d63d5745df/airlines_new.png");
  background-position: 21px 75px;
  background-size: 400px;
  width: 184px;
  height: 75px;
  float: left;
}
.airline-air-thanlwin-mobile {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/2c38yH74IN2IQi1nznIWZd/c81ce0a40df17bf6ad68f9d63d5745df/airlines_new.png");
  background-position: 207px 75px;
  background-size: 400px;
  width: 184px;
  height: 75px;
  float: left;
  display: none;
  margin-top: -23px;
}
/*airline image sprite*/

//tablet and desktoo
@media (min-width: 781px) {
  .bgHome.MuiContainer-root {
    padding: 0px !important;
  }
  .bgHome #DepartureGrid {
    border-right: 1px solid #e4e4e4;
    // margin-right: 21px;
    // width: calc(24% - (39.99996px + 10px));
    // min-width: calc(24% - (39.99996px + 10px)) !important;
    width: 24% !important;
  }
  .bgHome #swapDestinationIcon {
    pointer-events: none;
    border: 1px solid #e4e4e4;
    border-radius: 50px;
    padding: 5px;
    margin-top: 19px !important;
    background-color: #fff;
  }
  .bgHome #ArrivalGrid {
    // width: calc(24% - (40.99996px + 10px));
    // min-width: calc(24% - (40.99996px + 10px));
    width: 24% !important;
  }
  .bgHome #DateRangeDiv {
    border-left: 1px solid #e4e4e4;
    // border-right: 1px solid #e4e4e4;
    // padding-left: 20px;
    // width: calc(24% - (39.99996px + 11px));
    // min-width: calc(24% - (39.99996px + 11px));
    width: 24% !important;
  }
  .bgHome #tripTypeDiv {
    border-left: 1px solid #e4e4e4;
    // padding-left: 20px;
    // width: calc(24% - (39.99996px + 11px));
    // min-width: calc(24% - (39.99996px + 11px));
    // margin-right: 4px !important;
    width: 24% !important;
  }
}
@media (max-width: 780px) {
  .mdatepicker .SingleDatePicker_picker {
    position: fixed !important;
    top: 45% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    z-index: 10;
    border-radius: 3px;
  }
  #bg2 {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.3);
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
  }
  .footer-bottom {
    height: auto !important;
    td {
      height: 48px;
      font-size: 16px;
    }
  }
  .footerPhoneNo {
    height: 48px;
    line-height: 45px;
  }
  .DateInput_fang {
    display: none !important;
  }
  #ArrivalGrid {
    width: 100%;
  }

  #DepartureGrid {
    width: 100%;
  }
}
@media (min-width: 781px) {
  .CalendarDay__default {
    border: none !important;
  }
  .sortingBox .MuiInput-root.mm-pull-right {
    width: 175px !important;
  }
}

.tripTypeContainer.MuiGrid-item {
  padding: 0px 0px !important;
}

.tripTypeContainer .tripTypelabel {
  padding: 7px 12px;
  display: block;
}

.tripTypeLableSelected {
  background: #e8e8e8 !important;
  border-radius: 4px !important;
}
.tripTypeLableSelected span {
  color: #000000 !important;
  font-weight: 700 !important;
}
.tripType {
  margin-right: 5px !important;
}
.tripTypePopupTooltip {
  z-index: 10 !important;
}
.tripTypePopupTooltip div {
  width: 140px !important;
}
.tripTypePopupTooltip div::after {
  margin-left: 22px !important;
}
.returnText {
  font-family: "Poppins";
  font-weight: 300 !important;
  color: #231f20 !important;
  font-size: 28px !important;
  line-height: 32px !important;
}
.stayText {
  color: #8d8d8d !important;
  font-size: 18px !important;
  line-height: 20px !important;
}
.tripTypeDiv
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  margin-top: 16px !important;
}
#DateRangeDiv .calendarImgDiv {
  margin-left: 10px !important;
}

.appDownload {
  display: block !important;
  a {
    display: "inline-flex";
  }
  img {
    width: 150px;
    margin-right: 20px;
  }
}
.footer-app {
  margin-top: -10px !important;
}

.nationality-radio-btn {
  max-width: 74px !important;
}
.selectedDepartFlightNo {
  display: inline-block;
  width: 75%;
  font-size: 14px;
}
.privacyPolicy {
  width: 77% !important;
  font-family: "Roboto", Myanmar3 !important;
  margin: 20px auto !important;
  min-height: 100vh;
  p {
    line-height: 22px;
  }
}
#homeFlag {
  margin-left: 90% !important;
}
.flightSearch {
  z-index: 20 !important;
  left: 10% !important;
  display: block !important;
}
.flightPopup {
  //z-index: 1 !important;
  left: 15% !important;
  //top: -40px !important;
  //top: 10px !important;
  transform: translate3d(4px, 60px, 0px) !important;
}
.flightPopup.editSearch,
.flightToPopup.editSearch {
  top: 150px !important;
}
.flightToPopup {
  left: 16% !important;
  //top: 10px !important;
  transform: translate3d(209px, 60px, 0px) !important;
}
.flightPopupAuto {
  z-index: 10 !important;
  left: 15% !important;
  top: 40px !important;
  height: 400px !important;
  transform: translate3d(209px, 84px, 0px) !important;
}
.flightToPopupAuto {
  z-index: 10 !important;
  left: 12% !important;
  top: 40px !important;
  height: 400px !important;
}
// .top.home.fromAirport.airport-popup{
//   margin-top:-40px !important;
// }
.homeContainer .flightSearch .DateInput_input {
  font-size: 16px !important;
}
.contactUsTitle {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 10px;
}
.supportTitle {
  font-family: "Poppins", Myanmar3 !important;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 10px;
}
.contactUsGet {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}
.leaveMessage {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 10px;
  line-height: 20px;
}
.contactUsBox {
  padding-left: 50px;
  .fieldTitle {
    font-family: "Roboto" !important;
    font-size: 13px !important;
    font-weight: 500;
  }
  .errMessage {
    font-weight: 400;
    color: red;
    font-size: 12px;
    font-family: "Roboto" !important;
  }
}
.customerContainer .contactUsFieldBox {
  border: 1px solid #e6e7e8;
  border-radius: 4px;
  width: 100%;
  .MuiInputBase-input {
    height: 30px;
    padding: 5px 10px !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
    border: none !important;
  }
}
.customerContainer .contactUsMessageBox {
  border: 1px solid #e6e7e8 !important;
  border-radius: 4px;
  width: 100%;
  .MuiInputBase-input {
    padding: 5px 10px !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
    border: none !important;
  }
}
.btnSendMessage {
  background-color: #f7911e !important;
  border-radius: 4px;
  color: #fff !important;
  font-family: "Roboto", Myanmar3 !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  height: 45px;
  width: 150px;
}
.btnMessageBox {
  text-align: center !important;
  margin: 20px 0px !important;
  .MuiCircularProgress-root {
    color: #fff !important;
    width: 30px !important;
    height: 30px !important;
  }
}
.contactUsAddressBox {
  margin-top: 30px !important;
}
.officeAddressBox {
  padding-bottom: 30px;
  font-family: "Roboto", Myanmar3 !important;
  div:nth-child(3),
  div:nth-child(5) {
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  :nth-child(4) {
    padding-bottom: 20px;
  }
  span {
    font-size: 14px !important;
  }
}
.contactUsContainer {
  background-color: #fff !important;
  margin: 0px !important;
}
.visitUs {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}
.officeAddress {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-size: 14px;
  margin: 10px 0px;
  text-transform: capitalize !important;
  line-height: 20px;
  a {
    text-decoration: none;
    color: #000 !important;
    line-height: 30px;
  }
}
.officeAddress.emailAddress {
  text-transform: lowercase !important;
}
.officeAddressTitle {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 500;
  font-size: 16px;
  margin: 20px 0px 10px 0px;
}
.contactUsImg {
  width: 20px;
  height: 20px;
  padding-left: 10px;
}
.supportImage {
  width: 35px;
  height: 35px;
  margin-bottom: 10px;
}
.MuiSnackbar-root {
  left: 7% !important;
  .MuiSnackbarContent-root {
    background-color: #4caf50 !important;
  }
  .MuiSvgIcon-root {
    color: #fff !important;
  }
}
.searchErrMessage .contactUsFieldBox,
.searchErrMessage .contactUsMessageBox {
  border: none !important;
  .MuiInputBase-root {
    border: 1px solid #ff0000 !important;
    border-radius: 4px;
  }
}
.searchErrMessage {
  input {
    border: 1px solid #ff0000 !important;
  }
  .MuiInputBase-input {
    border: 1px solid #ff0000 !important;
  }
  .errMessage {
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
    color: #ff0000;
    font-size: 12px;
  }
}
.nopadding-bottom {
  padding-bottom: 0px !important;
}
.contactUsMenu a {
  color: #000 !important;
  padding: 19px 15px;
  font-family: "Roboto", Myanmar3 !important;
  float: right;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}
.mmContactUsMenu a {
  padding: 12px !important;
}
@media (max-width: 768px) {
  .contactUsBox {
    padding-left: 0px !important;
  }
  .contactUsAddressBox {
    padding-left: 20px !important;
  }
  .aboutUsImgContainer {
    padding: 30px 0px !important;
    img {
      width: 100% !important;
    }
  }
}
.aboutUsContent {
  font-size: 14px !important;
}
.aboutUsTitle {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 600;
  font-size: 24px;
  margin: 30px 0px;
}
.aboutUsProductsTitle {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 600;
  font-size: 18px;
}
.aboutUsProducts {
  text-align: center;
  padding-bottom: 30px !important;
  padding-top: 10px !important;
}
.aboutUsProductsDiv {
  padding: 20px 30px;
  font-family: "Roboto", Myanmar3 !important;
  p {
    font-size: 14px;
    font-family: "Roboto", Myanmar3 !important;
    // min-height: 110px !important;
    height: 150px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
  }
  img {
    width: 80px;
  }
  a {
    text-decoration: none !important;
  }
}
.aboutUsProductAds {
  max-width: 800px;
  margin: auto;
  div {
    margin: auto;
  }
}
.btnAboutUsBookNow {
  background-color: #f7911e !important;
  width: 220px;
  border-radius: 4px !important;
  color: #fff !important;
  height: 50px !important;
  border: none !important;
  text-transform: capitalize !important;
  span {
    font-weight: 600;
    font-family: "Roboto", Myanmar3 !important;
  }
}
.aboutUsImgContainer {
  padding: 60px 50px;
  img {
    width: 365px;
  }
}
.signatureContent {
  padding-top: 50px;
}

#mobileFlightDateRangeLableDiv {
  top: -40px !important;
  position: absolute !important;
  padding-left: 15px !important;
  background: #fff;
  min-height: 50px;
  width: 562px !important;
  padding-top: 10px !important;
  border-radius: 4px !important;
}
.dateLableDividerDiv {
  width: 96% !important;
  margin-top: 10px !important;
  margin-right: 20px !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  height: 1px !important;
  border: none !important;
}
#mobileFlightDateRangeLableDiv .flightType {
  width: auto !important;
  text-align: center !important;
}

.CalendarDay__default.CalendarDay__today {
  color: #484848 !important;
  border: none !important;
  border-radius: 6px !important;
}
.flightSearch .CalendarDay__selected.CalendarDay__today,
.editSearch .CalendarDay__selected.CalendarDay__today,
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  outline: none !important;
  color: #fff !important;
}

@media (max-width: 780px) {
  .DayPicker {
    min-width: 370px !important;
  }

  #mobileFlightDateRangeLableDiv {
    top: -230px !important;
    z-index: 100 !important;
    margin-left: -17px !important;
    width: 100% !important;
    padding-left: 17px !important;
    padding-right: 17px !important;
  }
}

.mobileFlightDateRangeLableDiv {
  display: none !important;
}
.confirmationBox.covidAlertBox {
  padding: 50px 5px !important;
  top: 50% !important;
  left: 35vw !important;
  width: 100%;
  max-width: unset;
  max-height: 100%;
  position: fixed;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  overflow-y: hidden;
  overflow-x: hidden;
}

@media (min-width: 781px) {
  .covidInfo {
    height: 40px;
  }
}
.covidInfo {
  border-bottom: 1px solid #fff;
  padding: 5px;
  position: absolute;
  width: 100%;
  top: 0;
  background-color: #29abe2;
  z-index: 2;
}
.covidInfoTitle {
  font-size: 15px !important;
  .highlight {
    color: #fff000 !important;
    font-weight: bold;
  }
}
.reliefTitle {
  font-size: 14px;
  font-weight: 600;
  font-family: Arial, Myanmar3 !important;
  line-height: 30px;
  color: #fff;
  text-align: center;
  margin-left: 5px;
}
.reliefTitle-highlight a {
  color: #fff000;
  &:hover {
    color: darken(#fff000, 1%);
  }
  &:visited,
  &:active {
    color: #fff000;
  }
}
.btnClose {
  float: right;
  color: #000 !important;
  font-size: 30px;
  top: 10px;
  right: 10px;
  position: absolute;
  cursor: pointer;
}
.covidAlertBox {
  padding: 5px 0px !important;
  img.closeImg {
    cursor: pointer;
  }
  img {
    width: 22px;
    position: relative;
    top: 5px;
    right: 5px;
  }
  .confirmationBoxTitle {
    text-align: left !important;
    font-family: "Poppins", Myanmar3 !important;
    line-height: 22px;
    .informationDiv {
      font-size: 14px !important;
      font-weight: 400 !important;
      text-align: left !important;
      font-family: "Poppins", Myanmar3 !important;
      a {
        color: #000 !important;
        text-decoration: underline !important;
        cursor: pointer;
      }
    }
  }
}
.bgWhiteBanner {
  background-color: #29abe2;
}

.menu a {
  display: inline-flex;
  align-items: center;
}
@media (min-width: 600px) and (max-width: 767px) {
  .progressbar li:not(:last-child):after {
    width: 60%;
  }
}

@media (max-width: 767px) {
  .progressBarContainer {
    justify-content: center;
  }
  .progressbar li {
    span {
      display: none;
    }
    &:last-child {
      width: unset !important;
      min-width: unset !important;
    }
  }
  .language-select {
    padding-right: 8px;
    justify-content: flex-end;
  }
  .selectedDepartureBox .detailBox {
    padding: 10px !important;
    margin-top: 20px;
  }
  div.airlineInfo.departureFlight {
    height: 80px;
    margin: auto !important;
    span img {
      width: 40px !important;
    }
  }
}
@media (min-width: 768px) and (max-width: 959px) {
  .mm-progress.progressbar,
  .eng-progress.progressbar {
    margin-left: 20%;
  }
  .eng-progress.progressbar li {
    min-width: 110px !important;
  }
  .eng-progress.progressbar li:not(:last-child):after {
    width: 50%;
    right: 5px;
  }
  .progressbar li:not(:last-child):after {
    right: 0px;
    width: 25%;
    bottom: 19px !important;
  }
  .mm-progress.progressbar li:first-child {
    min-width: 100px !important;
  }
}
//tablet size and above
@media (min-width: 768px) {
  .progressBarContainer {
    justify-content: center;
  }
  .language-select {
    justify-content: flex-end;
  }
  .progressbar .completed:before {
    width: 15px !important;
  }
  .mm-progressbar.progressbar {
    padding-top: 0;
  }
  .eng-progress.progressbar {
    padding-inline-start: 0px !important;
  }
  .mm-progress.progressbar li {
    align-items: center;
    min-width: 120px !important;
    width: unset !important;
    padding-left: 13px;
    padding-right: 13px;
  }
}
@media (min-width: 960px) {
  // .choosePassenger {
  //   max-width: unset;
  // }
  .progressbar {
    padding-top: 0;
  }
  .progressbar li:before {
    width: 14px;
    height: 14px;
  }
  .header .progressbar li {
    min-width: 110px !important;
  }
  .eng-progress.progressbar {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  .eng-progress.progressbar li:not(:last-child):after {
    width: 40%;
    bottom: 12px !important;
  }
  .mm-progress.progressbar {
    padding-top: 0;
    li {
      width: unset;
      min-width: 130px !important;
      display: block;
      padding-left: 10px;
      padding-right: 10px;
      &:not(:last-child):after {
        right: 0;
        z-index: 5;
        bottom: 11px !important;
      }
      &:first-child {
        min-width: 110px !important;
      }
      &:nth-child(3) {
        min-width: 115px !important;
      }
    }
  }
  .headerLogoContainer {
    display: flex;
    align-items: center;
    height: 60px;
  }

  .language-select {
    height: 60px;
    display: flex;
    align-items: center;
  }
  .infoSection .departTime {
    margin-left: 47px;
  }
  .infoSection .airlnelbl {
    margin-left: 20px;
  }
  .arlneLogo {
    margin-top: -24px;
  }
  .arlneLogo img {
    width: 44px;
  }
}
.headerLogoContainer {
  display: flex;
  align-items: center;
  height: 60px;
}
@media (min-width: 600px) and (max-width: 767px) {
  .progressbar li:not(:last-child):after {
    width: 60%;
  }
}

@media (max-width: 767px) {
  .progressBarContainer {
    justify-content: flex-start;
    padding-left: 16px;
    flex-basis: initial !important;
    margin-right: auto !important;
  }
  .progressbar li {
    span {
      display: none;
    }
    &:last-child {
      width: unset !important;
      min-width: unset !important;
    }
  }
  .language-select {
    padding-right: 16px;
    justify-content: flex-end;
  }
  .selectedDepartureBox .detailBox {
    padding: 10px !important;
    margin-top: 20px;
  }
  div.airlineInfo.departureFlight {
    height: 80px;
    margin: auto !important;
    span img {
      width: 40px !important;
    }
  }
}
@media (min-width: 768px) and (max-width: 959px) {
  .mm-progress.progressbar,
  .eng-progress.progressbar {
    margin-left: 20%;
  }
  .eng-progress.progressbar li {
    min-width: 110px !important;
  }
  .eng-progress.progressbar li:not(:last-child):after {
    width: 50%;
    right: 5px;
  }
  .progressbar li:not(:last-child):after {
    right: 0px;
    width: 25%;
    bottom: 19px !important;
  }
  .mm-progress.progressbar li:first-child {
    min-width: 100px !important;
  }
}
//tablet size and above
@media (min-width: 768px) {
  .progressBarContainer {
    justify-content: center;
  }
  .language-select {
    justify-content: flex-end;
  }
  .progressbar .completed:before {
    width: 5px !important;
  }
  .mm-progressbar.progressbar {
    padding-top: 0;
  }
  .eng-progress.progressbar {
    padding-inline-start: 0px !important;
  }
  .mm-progress.progressbar li {
    align-items: center;
    min-width: 120px !important;
    width: unset !important;
    padding-left: 13px;
    padding-right: 13px;
  }
}
@media (min-width: 960px) {
  // .choosePassenger {
  //   max-width: unset;
  // }
  .progressbar {
    padding-top: 0;
  }
  .progressbar li:before {
    width: 14px;
    height: 14px;
    margin: 3px 2px 0px 0px !important;
  }
  .header .progressbar li {
    min-width: 110px !important;
  }
  .eng-progress.progressbar {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  .eng-progress.progressbar li:not(:last-child):after {
    width: 40%;
    bottom: 12px !important;
  }
  .mm-progress.progressbar {
    padding-top: 0;
    li {
      width: unset;
      min-width: 130px !important;
      display: block;
      padding-left: 10px;
      padding-right: 10px;
      &:not(:last-child):after {
        right: 0;
        z-index: 5;
        bottom: 11px !important;
      }
      &:first-child {
        min-width: 110px !important;
      }
      &:nth-child(3) {
        min-width: 115px !important;
      }
    }
  }
  .headerLogoContainer {
    display: flex;
    align-items: center;
    height: 60px;
  }

  .language-select {
    height: 60px;
    display: flex;
    align-items: center;
  }
  .infoSection .departTime {
    margin-left: 47px;
  }
  .infoSection .airlnelbl {
    margin-left: 20px;
  }
  .arlneLogo {
    margin-top: -24px;
  }
  .arlneLogo img {
    width: 44px;
  }
}
.progressbar {
  counter-reset: step;
  font-size: 12px;
  text-align: right;
  max-width: 100%;
  text-align: center;

  li {
    position: relative !important;
  }
}
// .progressbar.mm-progress {
//   max-width: 95%;
//   // padding-inline-start: 0px !important;
// }
// .eng-progress {
//   float: right;
//   li:nth-child(2) {
//     width: 23% !important;
//   }
// }
.progressbar.eng-progress,
.progressbar.mm-progress {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  align-self: center;
}
.progressbar li {
  list-style-type: none;
  width: 23%;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: capitalize;
  color: #a1a1a1;
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 110px !important;
  span {
    float: left;
    margin-top: 3px;
    margin-left: 3px;
  }
}
// .mm-progress li:nth-child(2) {
//   width: 27% !important;
// }
// .mm-progress li:last-child {
//   width: 18% !important;
// }
.progressbar li:before {
  width: 16px;
  height: 16px;
  content: counter(step);
  counter-increment: step;
  color: #fff;
  line-height: 15px;
  border: 2px solid rgb(161, 161, 161);
  display: block;
  text-align: center;
  margin: 8px auto 0px auto;
  border-radius: 50%;
  background-color: rgb(161, 161, 161);
  float: left;
}
.progressbar .completed:before {
  content: url("../images/checked-symbol.svg") !important;
  background-color: #fff !important;
  border: none !important;
  margin-top: 8px;
  width: 20px !important;
  transform: scale(0.8);
}
.progressbar li:not(:last-child):after {
  background-color: #a1a1a1;
  width: 12px;
  height: 2px;
  content: "";
  position: absolute;
  right: 5px;
  z-index: 5;
  bottom: 14px !important;
}
.progressbar li:last-child:after {
  content: none !important;
}
.progressbar li.active {
  color: #27a1df;
  span {
    color: #27a1df !important;
  }
}
.progressbar li.active:before {
  border-color: #27a1df;
  background-color: #27a1df !important;
}
// .progressbar li.active + li:after {
//   background-color: #a1a1a1;
//   margin-top: -3px;
//   z-index: 5;
// }
// .progressbar li + li:after {
//   background-color: #a1a1a1;
//   margin-top: -3px;
//   padding-left: 5px;
//   z-index: 5;
// }
.bullet {
  margin: 0px 5px;
  font-size: 18px;
  top: 2px;
  position: relative;
}
.step-info {
  min-width: 30px;
  color: #a1a1a1;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-size: 14px;
}
.header {
  height: 60px;
  border-bottom: 1px solid #e6e6e6;
  background-color: #fff !important;
}
.headerLogo {
  display: block !important;
}
a {
  text-decoration: none;
  color: #fff;
}
.badge-icon {
  padding-left: 5px;
}

/***** Start Home Login Modal Box Css *****/
#logout {
  .MuiPaper-root.MuiPopover-paper {
    padding: 10px;
    width: 200px;
    span {
      width: 100%;
      display: inline-block;
      margin: 5px 0 10px 0;
    }
    span:hover {
      cursor: pointer;
      color: rgb(13, 135, 221);
    }
  }
}
.login-div {
  background: #fff;
  width: 60%;
  margin: 0 auto;
  margin-top: 5%;
  outline: none;

  .login-left {
    overflow-y: scroll;
    height: 70vh;
    .login-left-inner.signin {
      width: 60%;
      input {
        width: 100%;
      }
      .react-tel-input .flag-dropdown {
        background-color: #fff;
      }
    }
    .login-left-inner.email-sent {
      text-align: center;
      margin-top: 30px;
      max-width: 300px;
      img {
        width: 82px;
        height: auto;
        position: initial;
      }
      p {
        font-family: "Poppins", Myanmar3;
        font-size: 14px;
        color: #717171;
      }
      span.info {
        font-family: "Poppins", Myanmar3;
        font-size: 13px;
        color: #717171;
      }
    }
    button {
      font-family: "Poppins", Myanmar3;
      display: inline-block;
      width: 100%;
      text-align: center;
      color: #fff;
      background-color: #00aeef;
      border-radius: 4px;
      margin-top: 10px;
      margin-bottom: 20px;
      .MuiCircularProgress-root {
        width: 20px !important;
        height: 20px !important;
        color: #fff;
      }
    }
    .btn-login-box {
      border: 1px solid #3cbdfe;
      background-color: #fff;
      border-radius: 18px;
      display: flex;
      color: #000;
      .MuiButton-label {
        font-size: 15px;
      }
    }
    .MuiButton-label {
      text-transform: none;
    }
    .google-login button {
      width: 90%;
      margin-left: 20px;
    }
    .phone-login button {
      width: 90%;
      margin-left: 10px;
    }
    .signuptab-social-login {
      display: flex;
      margin-top: 40px;
    }
    .login-left-inner.signup {
      width: 85%;
      input {
        width: 90%;
      }
      .phone-container {
        position: relative;
        .react-tel-input {
          height: 20px;
          width: 70px !important;
          input {
            border: none !important;
            width: 0px !important;
          }
          .flag-dropdown {
            background-color: #fff !important;
            width: 100% !important;
            border: none !important;
            border-bottom: 1px solid #a5a7a6 !important;
            border-radius: 0;
            border-top: 0;
            border-right: 0;
            border-left: 0;
          }
          .selected-flag {
            width: 100% !important;
            padding-left: 7px !important;
            .flag {
              margin-top: -6px !important;
            }
            .arrow {
              left: 55px !important;
            }
          }
        }
        .countryCode {
          position: absolute;
          font-family: "Roboto", Myanmar3 !important;
          font-weight: 400;
          font-size: 13px !important;
          color: #231f20 !important;
          top: 20px;
          right: 20px;
          margin-top: 0;
        }
      }
      .nationality {
        width: 100%;
        border-bottom: 1px solid #a5a7a6;
        height: 18px;
        border-right: 0;
        border-top: 0;
        border-left: 0;
        outline: none;
      }
      .selectBox {
        border-bottom: 1px solid #a5a7a6 !important;
        height: 21px;
        border-right: 0 !important;
        border-top: 0 !important;
        border-left: 0 !important;
        outline: none;
        width: 80%;
        border-radius: 0 !important;
        padding: 0 !important;
      }
      .selectBoxFullWidthSignUpMobile {
        @media (max-width: 780px) {
          width: 100%;
        }
      }
      .regionSelect {
        width: 90%;
      }
      .nrcRegion,
      .dobRegion {
        margin-bottom: 0;
      }
      .nrcTextField {
        width: 90%;
      }
      .nrcNumber {
        height: 18px;
      }
      textarea {
        border-bottom: 1px solid #a5a7a6;
        border-right: 0;
        border-top: 0;
        border-left: 0;
        width: 100%;
        outline: none;
      }
    }
    .login-left-inner {
      height: auto;
      margin: 0 auto;
      .dim-div {
        pointer-events: none;
        opacity: 0.7;
      }
      h3 {
        font-family: "Poppins", Myanmar3;
        font-weight: 500;
        text-align: center;
        margin-top: 25px;
        margin-bottom: 20px;
      }
      .MuiGrid-root {
        margin-bottom: 15px;
        label {
          font-size: 14px !important;
          color: #a5a7a6;
          display: inline-block;
          width: 100%;
        }
        input {
          display: inline-block;
          border-top: 0;
          border-left: 0;
          border-right: 0;
          border-bottom: 1px solid #a5a7a6;
          outline: none;
          font-size: 14px !important;
        }
      }
      .MuiFormLabel-root,
      .MuiInputBase-root {
        font-size: 14px !important;
      }
      .error-textbox {
        border-bottom: 1px solid #ed4939 !important;
      }
      .MuiTextField-root {
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid #d4d4d4;
        margin-bottom: 25px;
        .MuiInput-formControl {
          width: 100%;
        }
      }
      .signin-back-btn {
        border: 1px solid #3cbdfe;
        color: #000;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 30px;
        padding: 8px 15px;
        display: inline-block;
        font-size: 14px;
      }
      .dim-btn-send-otp {
        pointer-events: none;
        opacity: 0.7;
      }
      .btn-cancel {
        width: 45%;
        background-color: #fff;
        border: 1px solid #ed4939;
        color: #ed4939;
      }
      .login-subtitle {
        font-size: 17px;
        color: #000;
        display: inline-block;
        margin-bottom: 30px;
        text-align: center;
        width: 100%;
      }
      p {
        font-size: 11px;
        margin-top: 2px;
        color: #ed4939;
      }
      .signup-error {
        color: #ed4939;
        font-size: 11px;
        margin-bottom: 0px;
      }
      .forgot-email {
        font-size: 14px;
        color: #3cbdfe;
        text-align: center;
        cursor: pointer;
        font-family: "Poppins", Myanmar3;
      }
      .forget-info {
        font-size: 14px;
        color: #717171;
        font-family: "Poppins", Myanmar3;
      }
      .forgot-div {
        text-align: center;
        margin-top: 5px;
        span {
          font-size: 14px;
          color: #948d8d;
          cursor: pointer;
        }
      }
      .react-tel-input {
        margin-bottom: 1px;
        .flag-dropdown {
          border: 0px;
          border-bottom: 1px solid #d4d4d4;
        }
        .MuiInputBase-formControl {
          border-radius: 0px;
          border-bottom: 1px solid #d4d4d4;
        }
      }
    }
  }

  .login-right {
    background-image: url("../images/login-box-bg.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
    background-position: 50% 50%;
    min-height: 400px;
    position: relative;
    img {
      position: absolute;
      width: 15px;
      height: 15px;
      right: 15px;
      top: 15px;
      cursor: pointer;
    }
    h4 {
      font-family: "Poppins", Myanmar3 !important;
      font-weight: 500;
      color: #f9fbfc;
      margin-top: 25px;
      margin-bottom: 15px;
    }
    span {
      font-family: "Poppins", Myanmar3 !important;
      font-weight: 100;
      color: #f9fbfc;
      display: inline-block;
      margin-top: 10px;
      font-size: 13px;
      padding: 0 20px;
    }
    button {
      position: absolute;
      border: 1px solid #d0e0eb;
      border-radius: 20px;
      bottom: 30px;
      left: 30px;
    }
    .MuiButton-label {
      font-weight: 500;
      margin-top: 0px !important;
      font-size: 14px;
    }
  }
}

@media (max-width: 780px) {
  .login-div {
    display: block !important;
    .login-container {
      overflow-y: initial !important;
    }
    .login-inner {
      height: 100vh;
      overflow-y: auto;
    }
    .login-left {
      height: auto;
      .login-left-inner.signup {
        input {
          width: 100%;
        }
        .phone-container {
          .countryCode {
            left: 35px;
            margin-left: 0 !important;
          }
        }
      }
    }
  }
}
/***** End Home Login Modal Box Css *****/

/*payment image sprite*/
.payment-img-visa {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/1MkKCyjCiATGFKwoDj0NHv/99ec5d3b0c404ad5c5822028329a9c23/Payment_icons.png");
  background-position: 0px 0px;
  background-size: 237px;
  width: 70px;
  height: 44px;
  float: left;
}
.payment-img-mobile-banking {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/1MkKCyjCiATGFKwoDj0NHv/99ec5d3b0c404ad5c5822028329a9c23/Payment_icons.png");
  background-position: -84px 0px;
  background-size: 237px;
  width: 70px;
  height: 44px;
  float: left;
}
.payment-img-mpu {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/1MkKCyjCiATGFKwoDj0NHv/99ec5d3b0c404ad5c5822028329a9c23/Payment_icons.png");
  background-position: -168px 0px;
  width: 70px;
  background-size: 237px;
  height: 44px;
  float: left;
}
.payment-img-123 {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/1MkKCyjCiATGFKwoDj0NHv/99ec5d3b0c404ad5c5822028329a9c23/Payment_icons.png");
  background-position: 0px -53px;
  background-size: 237px;
  width: 70px;
  height: 44px;
  float: left;
}
.payment-img-kbz-quickpay {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/1MkKCyjCiATGFKwoDj0NHv/99ec5d3b0c404ad5c5822028329a9c23/Payment_icons.png");
  background-position: -86px -52px;
  background-size: 237px;
  width: 70px;
  height: 44px;
  float: left;
}
.payment-img-cod {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/1MkKCyjCiATGFKwoDj0NHv/99ec5d3b0c404ad5c5822028329a9c23/Payment_icons.png");
  background-position: -168px -52px;
  background-size: 237px;
  width: 70px;
  height: 44px;
  float: left;
}
.payment-img-wave-pay {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/1MkKCyjCiATGFKwoDj0NHv/99ec5d3b0c404ad5c5822028329a9c23/Payment_icons.png");
  background-position: 0px -107px;
  width: 70px;
  height: 44px;
  float: left;
}
.payment-img-kbz-pay {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/1MkKCyjCiATGFKwoDj0NHv/99ec5d3b0c404ad5c5822028329a9c23/Payment_icons.png");
  background-position: -86px -105px;
  background-size: 237px;
  width: 70px;
  height: 44px;
  float: left;
}
.payment-img-citizen-pay {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/1MkKCyjCiATGFKwoDj0NHv/99ec5d3b0c404ad5c5822028329a9c23/Payment_icons.png");
  background-position: -86px -165px;
  background-size: 237px;
  width: 70px;
  height: 44px;
  float: left;
}
.payment-img-one-pay {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/1MkKCyjCiATGFKwoDj0NHv/99ec5d3b0c404ad5c5822028329a9c23/Payment_icons.png");
  background-position: -168px -105px;
  background-size: 237px;
  width: 70px;
  height: 44px;
  float: left;
}
.payment-img-mpitesan {
  // background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/1MkKCyjCiATGFKwoDj0NHv/99ec5d3b0c404ad5c5822028329a9c23/Payment_icons.png");
  background-position: 0px -166px;
  width: 70px;
  height: 44px;
  float: left;
}
/*payment image sprite*/
.confirmationBox.busInfo {
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}
.agentSwitch {
  float: right;
  font-size: 14px;
}
.signIn_btn {
  font-weight: 600;
  cursor: pointer;
}
.member-account {
  text-align: center;
  font-size: 14px;
}
.agentCheck {
  line-height: 40px;
  text-align: center;
}
.textLeft {
  text-align: left !important;
}
.confirmationBox.promotionBox {
  padding: 0px 7px 7px 7px !important;
  top: 50% !important;
  left: 35vw !important;
  width: 420px;
  max-width: unset;
  max-height: 100%;
  position: fixed;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: #fff !important;
  border-radius: 5px;
  text-align: left !important;
  p {
    margin: 0px !important;
  }
}
.google-btn {
  color: #000000 !important;
  display: flex !important;
  background-color: #fff !important;
}
.btn-sign-in.btn-facebook {
  display: flex !important;
}
.phoneInfo {
  font-size: 12px;
  color: #a1a1a1 !important;
}

.confirmationBox.promotionBox:focus {
  border: none !important;
  outline: none !important;
}
.promoImageGallery {
  padding-top: 30px;
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 200px;
    object-fit: cover;
  }
}
.mobContactUsMenu a {
  padding: 10px !important;
  float: left !important;
}

.pagi-region {
  display: flex;
  margin-top: 20px;
  margin-bottom: 80px;
  align-self: flex-start;
  margin-left: 20px;

  select {
    display: inline-flex;
    margin-left: 10px;
    height: 32px;
    padding: 0px 10px;
    outline: none;
    border: 1px solid #e3e3e3;
  }

  span {
    display: inline-flex;
    line-height: 30px;
    margin-left: 10px;
    font-size: 14px;
  }
}

ul.common-pagination {
  display: inline-flex;
  padding-left: 1px;
  list-style: none;
  border-radius: 0.25rem;
  margin: 0;

  li.active {
    a {
      color: #fff;
      background-color: #20a8d8;
      border-color: #20a8d8;
    }
  }

  li.disabled {
    a {
      color: #73818f;
      pointer-events: none;
      cursor: auto;
      background-color: #fff;
      border-color: #e3e3e3;
    }
  }

  li {
    a {
      position: relative;
      display: block;
      padding: 5px 10px;
      margin-left: -1px;
      line-height: 1.25;
      color: #20a8d8;
      background-color: #fff;
      border: 1px solid #e3e3e3;
      cursor: pointer;
    }
  }
}
.promoDesc a {
  color: #20a8d8 !important;
}
.google-btn {
  color: #000000 !important;
  display: flex !important;
  background-color: #fff !important;
}
.btn-sign-in.btn-facebook {
  display: flex !important;
}
.phoneInfo {
  font-size: 12px;
  color: #a1a1a1 !important;
}

.div-login-tab {
  display: flex;
}
.login-tab {
  width: 50%;
  border: 1px solid #eee;
  padding: 10px;
  font-size: 15px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  text-align: center;
  border-bottom: none !important;
  background-color: #f1f1f1;
  cursor: pointer;
}
.login-tab.active {
  background-color: #3cbdfe !important;
  color: #fff;
}
.login-inner {
  width: 75vh;
}
.infobox {
  border: 1px solid #3cbdfe;
  padding: 20px 20px;
  margin-bottom: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.infobox-loginbuttons {
  padding: 40px 20px;
}

#mui-component-select-sortBy {
  padding: 0px 0px 0px 10px !important;
  min-width: 120px !important;
  background-color: #fff !important;
  min-height: 30px;
  border-radius: 4px;
  line-height: 30px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-size: 14px !important;
  border: 1px solid #e4e4e4;
}
.sortingBox .MuiSvgIcon-root {
  color: rgba(0, 0, 0, 0.54) !important;
}
.sortingBox .promoSortBox {
  width: 200px;
  float: right;
  margin: 10px 20px;
}
.sortingBox .promoSortBox span {
  font-size: 14px;
  line-height: 35px;
}
.b2bTransferBox {
  background-color: white;
  padding: 20px;
  height: 330px;
}
.btn-transfer {
  width: 120px;
  float: right;
  margin: 20px 0px 0px 0px !important;
  background-color: #00aeef !important;
  color: #fff !important;
  text-transform: capitalize !important;
}
.transferAmountBox {
  border: 1px solid #eee !important;
  height: 330px !important;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}
.airportName {
  display: flex;
  flex-direction: row;
  justify-content: "space-between";
}
.airports {
  display: flex;
  flex-direction: row;
}

.airportNames:hover {
  cursor: pointer;
  color: #00aeef;
}
.airportDisableNames:hover {
  cursor: not-allowed;
  //color: #00aeef;
}
.intAirportNameDisable {
  opacity: 0.4 !important;
  pointer-events: none;
  &:hover {
    color: black !important;
    cursor: not-allowed;
  }
}
#international .MuiFormControlLabel-label,
#domestic .MuiFormControlLabel-label {
  margin-top: 8px;
}
#international .MuiIconButton-label,
#domestic .MuiIconButton-label {
  margin-top: 2px;
}
.promo-page-header-container {
  max-width: 1000px;
  margin: auto;
  @media (max-width: 781px) {
    max-width: auto;
    width: 93%;
  }
}

.promo-page-covid-banner-alert-space {
  @media (min-width: 781px) {
    margin-top: 35px;
  }
}

.flightToPopup.bottom.airport-popup:after {
  content: "";
  width: 26px;
  height: 14px;
  transform: rotate(-45deg);
  background: #01b7f2;
  position: absolute;
  border: 1px solid #e6e7e8;
  z-index: -1;
  bottom: 3px;
  left: 15%;
  position: absolute;
}
.right.airport-popup:after,
.left.airport-popup:after {
  content: "";
  transform: rotate(-45deg);
  background: #01b7f2;
  position: absolute;
  border: 1px solid #e6e7e8;
  z-index: -1;
  left: 15%;
  margin-left: -12px;
  width: 26px;
  height: 14px;
  overflow: hidden;
  margin-top: -3px;
}
.DateInput_fangStroke {
  stroke: #01b7f2 !important;
  fill: #01b7f2 !important;
}
