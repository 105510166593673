body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*[data-contentful-id]:not([data-contentful-id=""]):not([data-contentful-id="undefined"]):not([data-contentful-id="null"]):not([data-contentful-id="false"]):hover {
  border-radius: 3px;
  background-color: #D85A5C;
  transition: all 130ms 30ms ease-out;
}

*[data-contentful-id]:not([data-contentful-id=""]):not([data-contentful-id="undefined"]):not([data-contentful-id="null"]):not([data-contentful-id="false"]):hover::after {
  content: attr(data-contentful-id);
  display: inline-block;
  padding: 7px 12px;
  border-radius: 3px;
  color: rgb(255, 79, 82);
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 10000;
  font-size: 16px;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: 1px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  text-transform: none;
}

.move-up[data-contentful-id]:not([data-contentful-id=""]):not([data-contentful-id="undefined"]):not([data-contentful-id="null"]):not([data-contentful-id="false"]):hover::after {
  top: -40px;
  left: 0;
}